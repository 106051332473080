import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"
import PolicySection from "../components/_shared/PolicySection"

import cookiePolicyHTML from "!!raw-loader!../policies/cookie.html"

// ----------------------------------------------------------------------------
// GraphQL query - export and gatsby takes in automatic way
// ----------------------------------------------------------------------------

export const pageQuery = graphql`
  query {
    prismicCookiePolicy {
      id
      data {
        title {
          text
        }
        policy_content {
          html
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------

const CookiePage = (props) => {
  const { data } = props

  const prismicCookiePolicyObject = data && data.prismicCookiePolicy

  // -------------------------------------

  return (
    <Layout>
      <SEO title="Cookie" />
      <PolicySection
        title={
          prismicCookiePolicyObject &&
          prismicCookiePolicyObject.data &&
          prismicCookiePolicyObject.data.title &&
          prismicCookiePolicyObject.data.title.text
        }
        policyHTML={cookiePolicyHTML}
      />
    </Layout>
  )
}

export default CookiePage
